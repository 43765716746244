import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { loadProjects } from '@redux/projects.js'

import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import CursorPagination from '@components/Pagination/Cursor.jsx'

import './ProjectList.scss'

export default function ProjectList ({ pageLimit, showPagination = true }) {
	const [params, setParams] = useSearchParams()
	const [isEmpty, setIsEmpty] = useState(false)

	const direction = params.get('direction') || 'next'
	const before = params.get('before')
	const after = params.get('after')

	const [
		loading, projects,
		totalResults, remainingResults
	] = useSelector(({ projects }) => [
		projects.loading, projects.data,
		projects.totalResults, projects.remainingResults
	])

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadProjects({
			limit: pageLimit,
			withCustomer: true
		}))
			.then(({ totalResults }) => {
				if (!totalResults) {
					setIsEmpty(true)
				} else {
					setIsEmpty(false)
				}
			})
			.catch(error => {
				console.log('@loadprojects error', error)
			})
	}, [before, after, pageLimit])

	async function handlePageChange ({ before, after, direction }) {
		const params = {}

		if (before) {
			params.before = before
		}
		if (after) {
			params.after = after
		}
		if (direction) {
			params.direction = direction
		}

		setParams(params)
	}

	return (
		<div className='pd-projects-shared__list-container'>
      <ListRow
				className='pd-projects-shared__list'
				loading={loading}
				empty={isEmpty}
				headers={(
					<>
            <ListRowHeader
							className='pd-projects-shared__list__referral pd-projects-shared__list__referral--tab'
						>
              Client Name
            </ListRowHeader>
            <ListRowHeader
							className='pd-projects-shared__list__click pd-projects-shared__list__click--tab'
						>
              Submitted Date
            </ListRowHeader>
						<ListRowHeader
							className='pd-projects-shared__list__click pd-projects-shared__list__click--tab'
						>
              Current Status
            </ListRowHeader>
          </>
				)}
				items={projects?.map((click) => {
					return (
						<ListRowItem key={click.id}>
              <ListRowColumn className='pd-projects-shared__list__referral'>
                <div className='pd-projects-shared__list__mobile-tab'>Client Name:&nbsp;</div>
								{/*{click.customer?.name || 'N/A'}*/}
              </ListRowColumn>
              <ListRowColumn className='pd-projects-shared__list__click'>
                <div className='pd-projects-shared__list__mobile-tab'>Submitted Date:&nbsp;</div>
								{/*{DateTime.fromISO(click.created_at).toFormat('dd MMM, y')}&nbsp;*/}
              </ListRowColumn>
							<ListRowColumn className='pd-projects-shared__list__click'>
                <div className='pd-projects-shared__list__mobile-tab'>Current Status:&nbsp;</div>
								{/*{DateTime.fromISO(click.created_at).toFormat('dd MMM, y')}&nbsp;*/}
              </ListRowColumn>
            </ListRowItem>
					)
				})}
			/>
			{showPagination && (
				<CursorPagination
					direction={direction}
					cursor='created_at'
					showDetails
					items={projects}
					onPageChange={handlePageChange}
					pageLimit={pageLimit}
					totalResults={totalResults}
					remainingResults={remainingResults}
				/>
			)}
    </div>
	)
}

ProjectList.propTypes = {
	pageLimit: PropTypes.number,
	showPagination: PropTypes.bool
}
