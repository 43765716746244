import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { toggle } from '@redux/sidebar.js'
import { toggleAffiliateModal } from '@redux/referrals/withdrawals.js'

import Button from '@components/Button/index.jsx'

import logoLight from '@assets/images/shologo-formy-heycarson.svg'
import trendUp from '@assets/icons/trend-up.svg'

import './Sidebar.scss'

export default function MainSidebar (props) {
  const dispatch = useDispatch()

  const { name, showHelp = true, open, disabled, onClose } = props
  const [width] = useSelector(({ viewport }) => [viewport.width])
  const [balance] = useSelector(({ referrals: { withdrawals } }) => [withdrawals.balance])
  const { children } = props
  const canWithdraw = balance > 0

  const _ref = useRef()

  useEffect(() => {
    if (!open) {
      return
    }

    const handler = (event) => {
      if (!_ref.current.contains(event.target) && !_ref.current.isEqualNode(event.target)) {
        onClose(event)
      }
    }

    document.body.addEventListener('click', handler, {
      capture: true
    })

    return () => {
      document.body.removeEventListener('click', handler, {
        capture: true
      })
    }
  }, [open])

  function handleWithdraw () {
    dispatch(toggleAffiliateModal(open))
    dispatch(toggle(false))
  }

  return (
    <>
      <div
        ref={_ref}
        className={classnames('main-sidebar', {
          'main-sidebar--open': open,
          'main-sidebar--disabled': disabled
        })}
      >
        <div
          className={classnames('main-sidebar__overlay', {
            'main-sidebar__overlay--show': disabled
          })}
        />

        <Link to='/' className='main-sidebar__top' onClick={onClose}>
          <img className='main-sidebar__logo' src={logoLight} alt='' />
        </Link>

        {name && (
          <div className='main-sidebar__welcome'>
            Welcome back,
            <div className='main-sidebar__name'>{name}</div>
          </div>
        )}

        <div className='main-sidebar__items'>{children}</div>

        {width > 1199 && showHelp && (
          <div className='main-sidebar__footer'>
            Have a question? <br />
            <Link to={props.helpUrl} onClick={onClose}>
              partners@shopexperts.com
            </Link>
          </div>
        )}
        {width < 1200 && (
          <div className='main-sidebar__balance'>
            <div className='main-sidebar__balance__text'>
              Available balance is <span>${balance}</span>
            </div>
            <Button
              className='main-sidebar__withdraw'
              success
              small
              expanded
              disabled={!canWithdraw}
              onClick={handleWithdraw}
            >
              <img
                className='main-sidebar__withdraw__icon'
                src={trendUp}
                alt='withdraw'
              />
              Withdraw
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

MainSidebar.propTypes = {
  name: PropTypes.string,
  showHelp: PropTypes.bool,
  helpUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  disabled: PropTypes.bool
}
