import account from './account.js'
import referrals from './referrals/index.js'
import viewport from './viewport.js'
import themes from './themes.js'
import developers from './developers.js'
import themeFilters from './themeFilters.js'
import toastr from './toastr.js'
import sidebar from './sidebar.js'
import breadcrumbs from './breadcrumbs.js'
import themeReviews from './themeReviews.js'
import themeStores from './themeStores.js'
import themeInvites from './themeInvites.js'
import notificationSettings from './notificationSettings.js'
import appReviews from './appReviews.js'
import apps from './apps.js'
import appInvites from './appInvites.js'
import appCategories from './appCategories.js'
import appProfileClaims from './appProfileClaims.js'
import projects from './projects.js'

// import your reducers
export default {
  account,
  referrals,
  viewport,
  themes,
  developers,
  themeFilters,
  toastr,
  sidebar,
  breadcrumbs,
  themeReviews,
  themeStores,
  themeInvites,
  notificationSettings,
  apps,
  appReviews,
  appInvites,
  appCategories,
  appProfileClaims,
  projects
}
