import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ContentPadding from '@components/ContentPadding/index.jsx'

import OverviewHero from '@pages/Partners/Partner/Overview/Hero.jsx'
import ConversionList from '@containers/Partners/ConversionsList/index.jsx'

import './Overview.scss'
import classnames from 'classnames'
import ReferralsList from '@containers/Partners/ReferralsList/index.jsx'
import ClicksList from '@containers/Partners/ClicksList/index.jsx'

export default function OverviewPage () {
  // To be used to show/hide the see all button
  const [loading, totalResults] = useSelector(({ referrals: { conversions } }) => [
    conversions.loading,
    conversions.totalResults
  ])

  const [tab, setTab] = useState('conversions')


  return (
    <>
      <Helmet>
        <title>Overview</title>
      </Helmet>

      <ContentPadding>
        <div className='overview-page'>
          <OverviewHero />
                    <div className='referrals-page__tabs'>
            <Link
              to='#'
              className={classnames('referrals-page__tabs__tab', {
                'referrals-page__tabs__tab--selected': tab === 'conversions'
              })}
              onClick={() => setTab('conversions')}
            >
              Conversions
            </Link>
            <Link
              to='#'
              className={classnames('referrals-page__tabs__tab', {
                'referrals-page__tabs__tab--selected': tab === 'referrals'
              })}
              onClick={() => setTab('referrals')}
            >
              Account Signups
            </Link>
          </div>
          {tab === 'conversions' &&
            <ConversionList
              pageLimit={15}
              hash='referrals'
              showPagination
            />}
          {tab === 'referrals' &&
            <ReferralsList
              pageLimit={15}
              showPagination
            />}
          {tab === 'visits' &&
            <ClicksList
              pageLimit={15}
              showPagination
            />}
          {/* Only show see all button when conversion list contains data */}
          {!loading && totalResults > 0 && (
            <div className='overview-page__see-all'>
              <Link to='/referrals' className='overview-page__see-all__text'>
                See all
              </Link>
            </div>
          )}
        </div>
      </ContentPadding>
    </>
  )
}
